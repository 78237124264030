import * as React from "react";
// tslint:disable-next-line:no-duplicate-imports
import { Suspense } from "react";
import { withRouter, Switch, Route, BrowserRouter as Router, RouteComponentProps, Redirect } from "react-router-dom";

import ROUTES, { standaloneRoutes } from "./ducks/routes";
import { bindActionCreators, Dispatch } from "redux";
import { RoutesInterface, RoutesPropsInterface, RouteType } from "./ducks/types";

import Navigation from "./components/navigation";
import { connect } from "react-redux";
import { refreshProjectCache } from "../competitive-intelligence/ducks/actions";
import { getCookie } from "../app/duck/utils";
import { COOKIE_LFT_USER, COOKIE_SUB_PLAN } from "../app/const";
import { clearTableauAuthDetails } from "../custom-reporting/ducks/utils";
import { isUserAuthorized } from "../../src/user_account/ducks/utils";

// @ts-ignore
const NavigationWithRouter = withRouter(Navigation);
interface AllRoutesInterface {
    AllRoutes: RoutesInterface[];
    isHeaderVisible: boolean;
}

class Routes extends React.Component<RoutesPropsInterface, AllRoutesInterface> {
    // tslint:disable-next-line:no-any
    private refreshTimer: any;
    constructor(props: RoutesPropsInterface) {
        super(props);
        this.refreshTimer = null;
        this.state = {
            AllRoutes: getCookie(COOKIE_SUB_PLAN) || getCookie(COOKIE_LFT_USER) ? standaloneRoutes : ROUTES,
            isHeaderVisible: isUserAuthorized()
        };
    }

    public componentDidMount(): void {
        // reset tableau local storage details
        clearTableauAuthDetails();
        // Call first time when component mounts and for every 20min from there
        if (this.state.isHeaderVisible) {
            this.props.actions.refreshProjectCache();
            const delay = 20 * 60 * 1000; // min * sec * ms :::: 20 * 60 * 1000 = 20 mins
            this.refreshTimer = setInterval(() => {
                this.props.actions.refreshProjectCache();
            }, delay);
        }
    }

    public componentWillUnmount(): void {
        clearInterval(this.refreshTimer);
    }

    public componentDidUpdate(prevProps: RoutesPropsInterface): void {
        if (this.props.meta.routes.activeRouteUrl !== prevProps.meta.routes.activeRouteUrl) {
            this.setHeaderVisibility();
        }
    }

    public setHeaderVisibility = (): void => {
        const isHeaderVisible = isUserAuthorized();
        this.setState({ isHeaderVisible });
    }

    public render(): JSX.Element {
        const { meta, analytics } = this.props;
        const componentProps = {
            analytics,
            alpsMeta: meta
        };
        const { AllRoutes } = this.state;
        return (
            <Router>
                <Suspense fallback={<span>Loading...</span>}>
                    {!this.props.showProjectSetupNavbar && this.state.isHeaderVisible && <NavigationWithRouter />}
                    <Switch>
                        {AllRoutes.map(({ id, to, from, type, exact, Component }: RoutesInterface) =>
                            type === RouteType.ROUTE ? (
                                <Route
                                    key={id}
                                    path={to}
                                    exact={exact}
                                    // @ts-ignore
                                    render={(routeProps: RouteComponentProps) => <Component {...componentProps} {...routeProps} />}
                                />
                            ) : (
                                <Redirect key={id} from={from} to={to} exact={exact} />
                            )
                        )}
                    </Switch>
                </Suspense>
            </Router>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: {
        refreshProjectCache: bindActionCreators(refreshProjectCache, dispatch)
    }
});

// tslint:disable-next-line: no-any
const mapStateToProps = (state: any) => ({
    showProjectSetupNavbar: state.projectSetup.showProjectSetupNavbar
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
