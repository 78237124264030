enum MethodsType {
    GET = "get",
    POST = "post",
    PUT = "put",
    OPTIONS = "options",
    DELETE = "delete"
}
export const METHOD = {
    GET: MethodsType.GET,
    OPTIONS: MethodsType.OPTIONS,
    POST: MethodsType.POST,
    PUT: MethodsType.PUT,
    DELETE: MethodsType.DELETE
};
import { ApiPathInterface } from "../duck/types";
export const ApiPath: ApiPathInterface = {
    // HOME PAGE PATH VARS
    LOCALES_URL: "/alps/smb-api/locales",
    CREATE_PROJECT: "/alps/config/{{tenantCode}}/project",
    UPDATE_PROJECT_DETAILS: "/alps/config/{{tenantCode}}/project",
    GET_PROJECT_DETAIL: "/alps/config/{{tenantCode}}/project?id={{project_id}}",
    CREATE_SUB_DOMAINS: "/alps/config/{{tenantCode}}/project/{{project_id}}/subdomains",
    GET_SUBDOMAIN_LIST: "/alps/config/{{tenantCode}}/project/{{project_id}}/subdomains",
    DELETE_SUBDOMAINS: "/alps/config/{{tenantCode}}/project/{{project_id}}/subdomains",
    UPDATE_KEYWORD_DETAILS: "/alps/config/{{tenant_code}}/project/{{project_id}}/keywords",
    DELETE_KEYWORD: "/alps/config/{{tenant_code}}/project/{{project_id}}/keywords",
    PROJECT_DETAILS: "/alps/smb-api/project-domain-details/",
    TENANT_LIMITS: "/alps/auth/{{tenantCode}}/fetch-tenant-limits/{{userId}}",
    SERP_DATA_URL: "/alps/smb-api/keyword_serp_data",
    SERP_DATA_URL_BETA: "alps/smb-api/v4-0/keyword_serp_data",
    TRACK_REQUEST_URL: "/alps/smb-api/refresh_keyword/track-request",
    TRACK_REQUEST_URL_BETA: "/alps/smb-api/v4-0/refresh_keyword/track-request",
    DELETE_PROJECT_URL: "/alps/config/{{tenant_code}}/project?id={{project_id}}",
    CREATE_KEYWORD_AND_CATEGORY_MAP: "/alps/config/{{tenant_code}}/project/{{project_id}}/kw-cat-map",
    ADD_TO_OPTIMIZATION_KEYWORDS: "/alps/config/{{tenant_code}}/project/{{project_id}}/keywords",
    GET_KEYWORD_DETAILS: "/alps/config/{{tenantCode}}/project/{{project_id}}/keywords",
    GET_CATEGORY_LIST: "/alps/config/{{tenant_code}}/project/{{project_id}}/category",
    ADD_NEW_CATEGORY: "/alps/config/{{tenant_code}}/project/{{project_id}}/category",
    EDIT_CATEGORY_NAME: "/alps/config/{{tenant_code}}/project/{{project_id}}/category/{{category_id}}",
    DELETE_CATEGORY: "/alps/config/{{tenant_code}}/project/{{project_id}}/category",
    GET_COMPETITORS_LIST: "alps/config/{{tenant_code}}/project/{{project_id}}/competitors",
    POST_COMPETITOR_DOMAINS: "alps/config/{{tenant_code}}/project/{{project_id}}/competitors",
    DELETE_COMPETITOR_DOMAINS: "alps/config/{{tenant_code}}/project/{{project_id}}/competitors",
    GET_SUGGESTED_COMPETITOR_DOMAINS: "/alps/config/{{tenant_code}}/fetch-competitors",
    DOWNLOAD_SAMPLE_XLSX_TEMPLATE: "/alps/config/{{tenant_code}}/project/{{project_id}}/keywords/download",
    AGGREGATED_MODEL_SCORE_BETA: "/alps/real_time/v4-0/{{tenantCode}}/aggregated_model_scores",
    AGGREGATED_MODEL_SCORE: "/alps/real_time/{{tenantCode}}/aggregated_model_scores",
    AGGREGATED_MODEL_SCORE_DETAILS: "/alps/real_time/simulation/content/{{tenantCode}}/model_score_details",
    AGGREGATED_MODEL_SCORE_DETAILS_BETA: "/alps/real_time/v4-0/simulation/content/{{tenantCode}}/model_score_details",
    AGGREGATED_MODEL_KEYWORD_STUFFING: "/alps/real_time/v4-0/simulation/{{tenantCode}}/keyword_stuffing",
    AGGREGATED_PERFORMANCE: "/alps/real_time/{{tenantCode}}/aggregated_performance_metrics",
    COMPETITORS_SCORE: "/alps/real_time/{{tenantCode}}/competitor_scores",
    COMPETITORS_SCORE_BETA: "/alps/real_time/v4-0/{{tenantCode}}/competitor_scores",
    INIT_REQUEST_FOR_ID: "/alps/smb-api/refresh_keyword/initiate-request",
    INIT_REQUEST_FOR_ID_BETA: "/alps/smb-api/v4-0/refresh_keyword/initiate-request",
    KEYWORD_SERP: "/alps/smb-api/keyword_serp_data",
    KEYWORD_SERP_BETA: "alps/smb-api/v4-0/keyword_serp_data",
    KEYWORD_URL_DOWNLOAD: "/alps/smb-api/kw_url_download_data",
    KEYWORD_URL_DOWNLOAD_BETA: "/alps/smb-api/v4-0/kw_url_download_data",
    KEYWORD_URL_SCORE: "/alps/smb-api/keyword_url_score",
    KEYWORD_URL_SCORE_BETA: "/alps/smb-api/v4-0/keyword_url_score",
    ON_PAGE_ELEMENTS: "/alps/real_time/{{tenantCode}}/onpage_elements",
    ON_PAGE_ELEMENTS_BETA: "/alps/real_time/v4-0/{{tenantCode}}/onpage_elements",
    SIMULATE_AGGREGATED_MODEL_SCORE_DETAILS: "/alps/real_time/simulation/{{tenantCode}}/run-scenario-overall",
    SIMULATE_AGGREGATED_MODEL_SCORE_DETAILS_BETA: "/alps/real_time/v4-0/simulation/{{tenantCode}}/run-scenario-overall",
    PRE_SIM_KEYWORD_LEVEL_DETAIL_BETA: "/alps/real_time/v4-0/{{tenantCode}}/keyword_details",
    PRE_SIM_KEYWORD_LEVEL_DETAIL: "/alps/real_time/{{tenantCode}}/keyword_details",
    DOD_SUBMIT_REQUEST: "/alps/dod/{{tenantCode}}/submit",

    AUTH_GSC_URL: "/alps/config/{{tenant_code}}/project/{{project_id}}/gsc/auth",
    UNLINK_GSC_AUTH: "/alps/config/{{tenant_code}}/project/{{project_id}}/gsc/auth",
    GET_SITE_URLS_LIST: "/alps/config/{{tenant_code}}/project/{{project_id}}/gsc/siteurls",
    SUBMIT_SITE_URL: "/alps/config/{{tenant_code}}/project/{{project_id}}/gsc/siteurls",

    KWO_DETAIL_FETCH: "/alps/real_time/simulation/content/fetch_visible_content",
    KWO_FILTER_KEYWORDS: "/alps/manage/{{tenantCode}}/projects/{{projectId}}/themekeywords",
    KWO_KEYWORDS: "/alps/manage/{{tenantCode}}/projects/{{projectId}}/keywords",
    KWO_THEMES: "/alps/manage/{{tenantCode}}/projects/{{projectId}}/themes",

    SERP_DATA_DOWNLOAD: "/alps/smb-api/serp_data_download",
    SERP_DATA_DOWNLOAD_BETA: "/alps/smb-api/v4-0/serp_data_download",
    TOP_ORGANIC_URL: "/alps/smb-api/top_organic_urls",
    TOP_ORGANIC_URL_BETA: "/alps/smb-api/v4-0/top_organic_urls",
    USER_DETAILS: "/alps/smb-api/profile/user_details?session_token=alps_st_6xplco32vfkq5kbumjzfaveho7n0joi4",
    USER_PLANS_DETAILS: "/alps/smb-api/profile/{{tenantCode}}/subscription_details/?session_token=alps_st_6xplco32vfkq5kbumjzfaveho7n0joi4",

    PROJECTS_LIST: "/alps/config/{{tenantCode}}/project",

    RECOM_BULB_SUBHEADER_SCORES: "/alps/real_time/v4-0/{{tenantCode}}/simulation/recom_bulb_sub_header/",

    // MULTI KEYWORD API URLS
    MULTI_KEYWORD_INITIATE_REQUEST_URL: "/alps/smb-api/refresh_multiple_keyword/initiate-request",
    MULTI_KEYWORD_INITIATE_REQUEST_URL_BETA: "/alps/smb-api/v4-0/refresh_multiple_keyword/initiate-request",
    MULTI_KEYWORD_TRACK_REQUEST_URL: "/alps/smb-api/refresh_multiple_keyword/track-request",
    MULTI_KEYWORD_TRACK_REQUEST_URL_BETA: "/alps/smb-api/v4-0/refresh_multiple_keyword/track-request",
    THEME_KEYWORD_URL: "/alps/smb-api/{{tenantCode}}/projects/{{projectId}}/kw_theme_details?ranking_url={{rankingUrl}}&locale={{locale}}",
    PRE_SIMULATED_TECHNICAL_PARAMETER_SCORE: "/alps/real_time/iquanti/aggregated_tech_model_scores",
    PRE_SIMULATED_AUTHORITY_PARAMETER_SCORE: "/alps/real_time/iquanti/aggregated_auth_model_scores",
    NON_LIVE_URL_AGGREGATED_MODEL_SCORE: "/alps/real_time/{{tenantCode}}/static_model_scores",
    NON_LIVE_URL_AGGREGATED_MODEL_SCORE_BETA: "/alps/real_time/v4-0/{{tenantCode}}/static_model_scores",

    // COMPETITIVE INTELLIGENCE REPORTS API URLs
    REPORTS_API_URL: "/alps/reports/{{tenantCode}}/products/{{product}}/{{reportCode}}/sections",
    PROJECT_REPORTS_API_URL: "/alps/prt/tenant/{{tenantCode}}/projects/{{projectId}}/reports/{{reportCode}}/sections",
    FILE_UPLOAD_ON_PAGE_ELEMENTS: "/alps/real_time/{{tenantCode}}/non-live/parsed-html",
    FILE_UPLOAD_ON_PAGE_ELEMENTS_BETA: "/alps/real_time/v4-0/{{tenantCode}}/non-live/parsed-html",
    PRT_TARGET_DOMAIN: "/alps/prt/tenant/{{tenantCode}}/project_domain",

    // SAVE SIMULATION
    VERSION_LIST: "/alps/real_time/simulation/{{tenantCode}}/saved_simulations",
    VERSION_LIST_BETA: "alps/real_time/v4-0/simulation/{{tenantCode}}/saved_simulations",
    SCORES_UPDATE_BASED_ON_VERSION: "/alps/real_time/simulation/{{tenantCode}}/saved_simulation_scores",
    SAVE_SIMULATION_CONTENT: "/alps/real_time/simulation/{{tenantCode}}/save_simulation/content",
    SAVE_SIMULATION_CONTENT_BETA: "/alps/real_time/v4-0/simulation/{{tenantCode}}/save_simulation/content",
    SAVE_SIMULATION_META_INFO: "/alps/real_time/simulation/{{tenantCode}}/save_simulation/meta_details",
    SAVE_SIMULATION_META_INFO_BETA: "/alps/real_time/v4-0/simulation/{{tenantCode}}/save_simulation/meta_details",
    SAVE_SIMULATION_AUTHORITY_TECHNICAL_PARAMETER: "/alps/real_time/simulation/{{tenantCode}}/saved_simulations/model_parameters",
    SAVE_SIMULATION_AUTHORITY_TECHNICAL_PARAMETER_BETA: "/alps/real_time/v4-0/simulation/{{tenantCode}}/saved_simulations/model_parameters",
    SAVE_SIMULATION_HISTORY: "/alps/real_time/simulation/{{tenantCode}}/saved_simulations/history",
    SAVE_SIMULATION_HISTORY_BETA: "/alps/real_time/v4-0/simulation/{{tenantCode}}/saved_simulations/history",
    SAVE_SIMULATION_COMPETITORS_SCORE: "/alps/real_time/simulation/{{tenantCode}}/saved_simulations/competitor_scores/",
    SAVE_SIMULATION_COMPETITORS_SCORE_BETA: "/alps/real_time/v4-0/simulation/{{tenantCode}}/saved_simulations/competitor_scores/",
    SAVE_SIMULATION_VERSION_STATUS: "/alps/real_time/simulation/{{tenantCode}}/saved_simulations/version/status",
    SAVE_SIMULATION_VERSION_STATUS_BETA: "/alps/real_time/v4-0/simulation/{{tenantCode}}/saved_simulations/version/status",
    RELATED_KEYWORD_INITIATE_REQUEST_URL: "/alps/smb-api/related_kwd/v1/{{type}}/initiate",
    COMPETITORS_SCORE_INITIATE_REQUEST_URL: "alps/real_time/v4-0/simulation/{{tenantCode}}/competitor_scores/initiate/",
    SAVE_SIMULATION_COMPETITORS_SCORE_INITIATE_REQUEST_URL:
        "alps/real_time/v4-0/simulation/{{tenantCode}}/saved_simulations/competitor_scores/initiate/",
    SAVE_SIMULATION_COMPETITORS_SCORE_SSE: "alps/real_time/v4-0/simulation/{{tenantCode}}/competitor_scores/sse/",

    // CONTENT BRIEF PATH
    GET_CONTENT_BRIEF_HISTORY: "/alps/content-brief-api/{{tenantCode}}/content-briefs",
    GET_CONTENT_BRIEF_PROGRESS: "/alps/content-brief-api/{{tenantCode}}/content-briefs/{{id}}",
    CONTENT_BRIEF_DOWNLOAD: "/alps/content-brief-api/{{tenantCode}}/content-briefs/{{id}}?format=docx",
    // CONTENT_BRIEF_WITH_TYPE_API_URL used in edit content brief name, add-edit description and get content brief summary
    CONTENT_BRIEF_WITH_TYPE_API_URL: "/alps/content-brief-api/{{tenantCode}}/content-briefs/{{id}}/{{type}}",

    // PRT CACHE REFRESH
    PRT_PROJECT_CACHE_REFRESH: "/alps/prt/tenant/{{tenantCode}}/projects/{{projectId}}/cache-warmer",

    // PRT TARGET DOMAIN & KEYWORD CATEGORY
    PRT_DEAFULT_PROJECT_CATEGORY: "/alps/prt/tenant/{{tenantCode}}/project/{{projectId}}/default_category",
    PRT_DEAFULT_PROJECT_TA_DOMAIN: "/alps/prt/tenant/{{tenantCode}}/project/{{projectId}}/default_ta_domain",
    PRT_SOV_TARGET_COMPETITOR_DOMAINS: "/alps/prt/tenant/{{tenantCode}}/project/{{projectId}}/sov_target_competitor_domains",

    // KRT Related Keyword
    RELATED_KEYWORD_INITIATE_REQUEST: "/alps/smb-api/related_kwd/v1/{{type}}/initiate",
    // TO call Related Keyword SSE service with keyword param
    RELATED_KEYWORD_SSE_API: "/orchestration/related_kwd/v1/sse",
    // TO call Related Keyword SSE service with URL param
    RELATED_KEYWORD_URL_SSE_API: "/orchestration/related_kwd/v1/url/sse",

    SUGGESTED_RELATED_KEYWORD_INITIATE_REQUEST_URL: "/alps/smb-api/related_kwd/v1/keyword/initiate",

    // Auto login API
    AUTO_LOGIN_API_URL: "/alps/auth/trial_user_auth",

    // Update Subscription Plan API
    UPDATE_SUBSCRIPTION_API_URL: "/alps/auth/{{tenant_code}}/update-subscription",

    // Email Verification
    USER_EMAIL_VERIFICATION: "/alps/auth/verify/user",
    USER_EMAIL_REVERIFICATION: "/alps/auth/user-activate",

    // Project Setup APIs
    ADD_KEYWORDS_API_URL: "/alps/config/{{tenantCode}}/project/{{project_id}}/keywords",

    ASSIGN_NEW_CATEGORY: "/alps/config/{{tenant_code}}/project/{{project_id}}/kw-cat-map",

    KEYWORD_FILE_UPLOAD: "/alps/config/{{tenant_code}}/project/{{project_id}}/keywords/uploads",

    // Recommendation APIs
    CONTEXTUAL_RECOMMENDATION: "alps/real_time/v4-0/simulation/{{tenantCode}}/recommendation/contextual/sse",
    CONTEXTUAL_RECOMMENDATION_INITIATE: "alps/real_time/v4-0/simulation/{{tenantCode}}/recommendation/contextual/initiate/",
    FETCH_PHRASE_CONTENT: "alps/real_time/v4-0/simulation/phrases",
    FETCH_TOP_COMPETITOR_URLS: "alps/real_time/v4-0/simulation/top_urls",

    // Download Page Simulation Word API
    SIMULATION_DOWNLOAD_API: "/alps/real_time/v4-0/simulation/{{tenantCode}}/saved_simulations/download",
    SIMULATION_DOWNLOAD_CONTENT_ONLY_API: "/alps/real_time/v4-0/simulation/{{tenantCode}}/saved_simulations/content/download",
    SIMULATION_DOWNLOAD_CONTENT_ONLY_TRACK_API: "/alps/real_time/v4-0/simulation/{{tenantCode}}/saved_simulations/content/download/track",
    SIMULATION_DOWNLOAD_PAGE_ASSESSMENT: "/alps/real_time/v4-0/simulation/{{tenantCode}}/page_assessment/content/download",

    // Custom Reporting APIs
    TABLEAU_EMBED_TOKEN_API_URL: "/alps/custom-bi/tenant/{{tenantCode}}/embed-token/{{userId}}",

    // KW-Page Intent API
    KW_LEVEL_PAGE_INTENT: "/alps/real_time/v4-0/simulation/insights/kw_article_intent",

    // Generate Content Outline API
    GENERATE_CONTENT_OUTLINE: "alps/real_time/v4-0/{{tenant_code}}/content_outline",

    // Performance Impact APIs
    GET_PERFORMANCE_IMPACT_REPORTS_LIST: "/alps/performance-impact-api/{{tenant_code}}/reports/",
    GET_PERFORMANCE_IMPACT_REPORT_DETAILS: "/alps/performance-impact-api/{{tenant_code}}/reports/{{report_id}}/device_type/{{device_type}}/",
    GET_PERFORMANCE_IMPACT_REPORT_RANKING_KEYWORDS: "/alps/performance-impact-api/{{tenant_code}}/reports/{{report_id}}/device_type/{{device_type}}/ranking-keywords/",
    GET_PERFORMANCE_IMPACT_REPORT_GSC_SUMMARY: "/alps/performance-impact-api/{{tenant_code}}/reports/{{report_id}}/device_type/{{device_type}}/gsc-summary/",
    GET_PERFORMANCE_IMPACT_REPORT_GSC_KEYWORDS: "/alps/performance-impact-api/{{tenant_code}}/reports/{{report_id}}/device_type/{{device_type}}/gsc-keywords/",

    // User Account APIs
    CHANGE_TENANT_API_URL: "/alps/auth/{{selectedTenant}}/changetenant",
    USER_LOGIN: "alps/auth/userauth",
    USER_LOGIN_WITH_TENANT: "alps/auth/tenant/userauth",
    LOGOUT_API_URL: "alps/auth/{{tenant_code}}/logout",
    USER_LOGIN_SSO: "alps/auth/sso/login",
    FORGOT_PASSWORD: "alps/auth/forgotpassword",
    CHANGE_USER_PASSWORD: "/alps/auth/{{tenant_code}}/changepassword",
    CHECK_SSO_LOGIN: "alps/auth/sso/verify",
    RESET_PASSWORD: "alps/auth/resetpassword"
};
