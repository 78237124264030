import { createAction } from "redux-actions";

const actionTypes = {
    SET_SHOW_PROJECT_SETUP_HEADER: "SET_SHOW_PROJECT_SETUP_HEADER",
    CALL_TO_CREATE_PROJECT: "CALL_TO_CREATE_PROJECT",
    SET_STATUS_CODE_OF_CREATE_PROJECT_API: "SET_STATUS_CODE_OF_CREATE_PROJECT_API",
    CALL_TO_SAVE_PROJECT_KEYWORDS: "CALL_TO_SAVE_PROJECT_KEYWORDS",
    SET_KEYWORDS_ADDED_SUCCESS: "SET_KEYWORDS_ADDED_SUCCESS",
    SET_PROJECT_SETUP_HEADER_TEXT: "SET_HEADER_TEXT",
    CALL_TO_UPDATE_PROJECT_DETAILS: "CALL_TO_UPDATE_PROJECT_DETAILS",
    CALL_TO_GET_PROJECT_DETAILS: "CALL_TO_GET_PROJECT_DETAILS",
    SAVE_SUBDOMAIN_LIST: "SAVE_SUBDOMAIN_LIST",
    SAVE_PROJECT_DETAILS: "SAVE_PROJECT_DETAILS",
    SET_PROJECT_CREATE_JOURNEY: "SET_PROJECT_CREATE_JOURNEY",
    SET_PROJECT_EDIT_JOURNEY: "SET_PROJECT_EDIT_JOURNEY",
    RESET_PROJECT_DETAILS: "RESET_PROJECT_DETAILS",
    CALL_TO_ADD_CATEGORY: "CALL_TO_ADD_CATEGORY",
    CALL_TO_GET_CATEGORY_LIST: "CALL_TO_GET_CATEGORY_LIST",
    CALL_TO_EDIT_CATEGORY: "CALL_TO_EDIT_CATEGORY",
    CALL_TO_DELETE_CATEGORY: "CALL_TO_DELETE_CATEGORY",
    SAVE_CATEGORY_LIST: "SAVE_CATEGORY_LIST",
    CALL_TO_GET_SITE_URLS_LIST: "CALL_TO_GET_SITE_URLS_LIST",
    CALL_TO_GET_UNLINK_GSC_ACCOUNT: "CALL_TO_GET_UNLINK_GSC_ACCOUNT",
    SAVE_SITE_URLS_LIST: "SAVE_SITE_URLS_LIST",
    CALL_TO_SUBMIT_SITE_URL: "CALL_TO_SUBMIT_SITE_URL",
    CALL_TO_ASSIGN_CATEGORY_PRESENT: " CALL_TO_ASSIGN_CATEGORY_PRESENT",
    CALL_TO_GET_KEYWORD_DATA: "CALL_TO_GET_KEYWORD_DATA",
    SAVE_LIST_OF_OPTIMIZED_KEYWORDS: "SAVE_LIST_OF_OPTIMIZED_KEYWORDS",
    SAVE_KEYWORD_DETAILS: "SAVE_KEYWORD_DETAILS",
    SAVE_ADDED_KEYWORD_IDS: "SAVE_ADDED_KEYWORD_IDS",
    CLEAR_ADDED_KEYWORD_IDS: "CLEAR_ADDED_KEYWORD_IDS",
    MAP_KEYWORD_AND_CATEGORIES: "MAP_KEYWORD_AND_CATEGORIES",
    ADD_TO_OPTIMIZATION_KEYWORDS: "ADD_TO_OPTIMIZATION_KEYWORDS",
    SET_FILTER_KEYWORD: "SET_FILTER_KEYWORD",
    SET_ACTIVE_PROJECT_ID: "SET_ACTIVE_PROJECT_ID",
    SET_PROJECT_SETUP_SELECTED_KEYWORDS: "SET_PROJECT_SETUP_SELECTED_KEYWORDS",
    SET_PROJECT_SETUP_SELECTED_CATEGORIES: "SET_PROJECT_SETUP_SELECTED_CATEGORIES",
    CALL_TO_ASSIGN_NEW_CATEGORIES: "CALL_TO_ASSIGN_NEW_CATEGORIES",
    // ASSIGN_NEW_CATEGORIES_SUCCESS: "ASSIGN_NEW_CATEGORIES_SUCCESS",
    UPDATE_KEYWORD_DETAILS: "UPDATE_KEYWORD_DETAILS",
    DELETE_KEYWORDS_FROM_PROJECT: "DELETE_KEYWORDS_FROM_PROJECT",
    SET_STATUS_CODE_OF_DELETE_KW_API: "SET_STATUS_CODE_OF_DELETE_KW_API",
    CALL_TO_GET_COMPETITORS_LIST: "CALL_TO_GET_COMPETITORS_LIST",
    SAVE_COMPETITORS_LIST: "SAVE_COMPETITORS_LIST",
    CALL_TO_POST_COMPETITOR_DOMAINS: "CALL_TO_POST_COMPETITOR_DOMAINS",
    CALL_TO_DELETE_COMPETITOR_DOMAINS: "CALL_TO_DELETE_COMPETITOR_DOMAINS",
    SET_STATUS_CODE_OF_SAVE_COMPETITOR_API: "SET_STATUS_CODE_OF_SAVE_COMPETITOR_API",
    SET_IS_KW_RESEARCH_ADD_CATEGORY_SUCCESS: "SET_IS_ASSIGN_CATEGORY_SUCCESS",
    SET_IS_ADD_KEYWORD_LIMIT_EXCEEDED: "SET_IS_ADD_KEYWORD_LIMIT_EXCEEDED",
    SET_STATUS_CODE_OF_SUBMIT_SITEURL_API: "SET_STATUS_CODE_OF_SUBMIT_SITEURL_API",
    SET_TARGET_URL_ASSIGNED_SUCCESS: "SET_TARGET_URL_ASSIGNED_SUCCESS",
    SET_MK_NOTIFICATION_DETAILS: "SET_MK_NOTIFICATION_DETAILS",
    SET_IS_OPTIMIZATION_KEYWORD_SUCCESS: "SET_IS_OPTIMIZATION_KEYWORD_SUCCESS",
    SET_ADD_KW_NOTIFICATION_DETAILS: "SET_ADD_KW_NOTIFICATION_DETAILS",
    RESET_IS_KEYWORDS_OPTIMIZED_STATUS: "RESET_IS_KEYWORDS_OPTIMIZED_STATUS",
    RESET_IS_KEYWORDS_CATEGORY_MAPPED_STATUS: "RESET_IS_KEYWORDS_CATEGORY_MAPPED_STATUS",
    RESET_KWD_CATEGORY_AND_KWD_OPTIMIZATION_STATUS: "RESET_KWD_CATEGORY_AND_KWD_OPTIMIZATION_STATUS",
    CALL_TO_GET_SUGGESTED_COMPETITOR_DOMAINS: "CALL_TO_GET_SUGGESTED_COMPETITOR_DOMAINS",
    SAVE_SUGGESTED_COMPETITOR_DOMAINS: "SAVE_SUGGESTED_COMPETITOR_DOMAINS",
    SET_SUGGESTED_COMPETITOR_DOMAIN_API_STATUS: "SET_SUGGESTED_COMPETITOR_DOMAIN_API_STATUS",
    SET_CURRENT_PAGINATION_FOR_KEYWORD_DETAILS: "SET_CURRENT_PAGINATION_FOR_KEYWORD_DETAILS",
    CALL_TO_SAVE_UPLOAD_PROJECT_KEYWORD: "CALL_TO_SAVE_UPLOAD_PROJECT_KEYWORD",
    SET_KEYWORD_UPLOAD_FILE: "SET_KEYWORD_UPLOAD_FILE",
    SET_IS_UPLOAD_FILE_KEYWORD_SUCCESS: "SET_IS_UPLOAD_FILE_KEYWORD_SUCCESS",
    RESET_DOMAIN_DETAILS_STATE_STATUS: "RESET_DOMAIN_DETAILS_STATE_STATUS",
    RESET_ADD_KEYWORD_STATE_STATUS: "RESET_ADD_KEYWORD_STATE_STATUS",
    SET_PROJECT_DEVICE_TYPE: "SET_PROJECT_DEVICE_TYPE",
    SET_ERROR_FILE_STATUS: "SET_ERROR_FILE_STATUS",
    SET_ERROR_FILE_API_RESPONSE: "SET_ERROR_FILE_API_RESPONSE",
    RESET_FILE_UPLOAD_STATUS: "RESET_FILE_UPLOAD_STATUS",
    SET_PROJECT_CRAWLING_FREQUENCY: "SET_PROJECT_CRAWLING_FREQUENCY",
    SET_PROJECT_INITIAL_CRAWLING_FREQUENCY: "SET_PROJECT_INITIAL_CRAWLING_FREQUENCY"
};
export default actionTypes;

export const setShowProjectSetupHeader = createAction(actionTypes.SET_SHOW_PROJECT_SETUP_HEADER);
export const callToSaveProjectKeywords = createAction(actionTypes.CALL_TO_SAVE_PROJECT_KEYWORDS);
export const setKeywordsAddedSuccess = createAction(actionTypes.SET_KEYWORDS_ADDED_SUCCESS);
export const setProjectSetupHeaderText = createAction(actionTypes.SET_PROJECT_SETUP_HEADER_TEXT);
export const callToCreateProject = createAction(actionTypes.CALL_TO_CREATE_PROJECT);
export const setStatusCodeOfCreateProjectAPI = createAction(actionTypes.SET_STATUS_CODE_OF_CREATE_PROJECT_API);
export const callToUpdateProjectDetails = createAction(actionTypes.CALL_TO_UPDATE_PROJECT_DETAILS);
export const callToGetProjectDetails = createAction(actionTypes.CALL_TO_GET_PROJECT_DETAILS);
export const saveSubDomainList = createAction(actionTypes.SAVE_SUBDOMAIN_LIST);
export const saveProjectDetails = createAction(actionTypes.SAVE_PROJECT_DETAILS);
export const setProjectCreateJourney = createAction(actionTypes.SET_PROJECT_CREATE_JOURNEY);
export const setProjectEditJourney = createAction(actionTypes.SET_PROJECT_EDIT_JOURNEY);
export const callToCreaeteProject = createAction(actionTypes.CALL_TO_CREATE_PROJECT);
export const callToAddCategory = createAction(actionTypes.CALL_TO_ADD_CATEGORY);
export const callToGetCategoryList = createAction(actionTypes.CALL_TO_GET_CATEGORY_LIST);
export const callToEditCategory = createAction(actionTypes.CALL_TO_EDIT_CATEGORY);
export const callToDeleteCategory = createAction(actionTypes.CALL_TO_DELETE_CATEGORY);
export const saveCategoryList = createAction(actionTypes.SAVE_CATEGORY_LIST);
export const callToGetSiteURLSList = createAction(actionTypes.CALL_TO_GET_SITE_URLS_LIST);
export const saveSiteURLSList = createAction(actionTypes.SAVE_SITE_URLS_LIST);
export const callToUnlinkAccount = createAction(actionTypes.CALL_TO_GET_UNLINK_GSC_ACCOUNT);
export const callToSubmitSiteURL = createAction(actionTypes.CALL_TO_SUBMIT_SITE_URL);

export const callToAssignCategoryPresent = createAction(actionTypes.CALL_TO_ASSIGN_CATEGORY_PRESENT);
export const callToGetKeywordData = createAction(actionTypes.CALL_TO_GET_KEYWORD_DATA);
export const saveListOfOptimizedKeywords = createAction(actionTypes.SAVE_LIST_OF_OPTIMIZED_KEYWORDS);
export const saveAddedKeywordIDs = createAction(actionTypes.SAVE_ADDED_KEYWORD_IDS);
export const clearAddedKeywordIDs = createAction(actionTypes.CLEAR_ADDED_KEYWORD_IDS);
export const mapKeywordAndCategories = createAction(actionTypes.MAP_KEYWORD_AND_CATEGORIES);
export const addToOptimizationKeywords = createAction(actionTypes.ADD_TO_OPTIMIZATION_KEYWORDS);
export const saveKeywordDetails = createAction(actionTypes.SAVE_KEYWORD_DETAILS);
export const setActiveProjectId = createAction(actionTypes.SET_ACTIVE_PROJECT_ID);
export const setFilterKeywords = createAction(actionTypes.SET_FILTER_KEYWORD);
export const setSelectedProjectKeywords = createAction(actionTypes.SET_PROJECT_SETUP_SELECTED_KEYWORDS);
export const setSelectedProjectCategories = createAction(actionTypes.SET_PROJECT_SETUP_SELECTED_CATEGORIES);
export const callToAssignNewCategories = createAction(actionTypes.CALL_TO_ASSIGN_NEW_CATEGORIES);
// export const assignNewCategoriesSuccess = createAction(actionTypes.ASSIGN_NEW_CATEGORIES_SUCCESS);
export const updateKeywordDetails = createAction(actionTypes.UPDATE_KEYWORD_DETAILS);
export const deleteKeywordsFromProject = createAction(actionTypes.DELETE_KEYWORDS_FROM_PROJECT);
export const callToGetCompetitorsList = createAction(actionTypes.CALL_TO_GET_COMPETITORS_LIST);
export const saveCompetitorsList = createAction(actionTypes.SAVE_COMPETITORS_LIST);
export const callToPostCompetitorDomains = createAction(actionTypes.CALL_TO_POST_COMPETITOR_DOMAINS);
export const callToDeleteCompetitorDomains = createAction(actionTypes.CALL_TO_DELETE_COMPETITOR_DOMAINS);
export const setStatusCodeOfSaveCompetitorAPI = createAction(actionTypes.SET_STATUS_CODE_OF_SAVE_COMPETITOR_API);
export const setIsKwResearchAddCategorySuccess = createAction(actionTypes.SET_IS_KW_RESEARCH_ADD_CATEGORY_SUCCESS);
export const setIsAddKeywordLimitExceeded = createAction(actionTypes.SET_IS_ADD_KEYWORD_LIMIT_EXCEEDED);
export const setStatusCodeOfSubmitSiteurlAPI = createAction(actionTypes.SET_STATUS_CODE_OF_SUBMIT_SITEURL_API);
// export const setTargetUrlAssignedSuccess = createAction(actionTypes.SET_TARGET_URL_ASSIGNED_SUCCESS);
export const setMkNotificationDetails = createAction(actionTypes.SET_MK_NOTIFICATION_DETAILS);
export const setIsOptimizationKeywordsSuccess = createAction(actionTypes.SET_IS_OPTIMIZATION_KEYWORD_SUCCESS);
export const setAddKwNotificationDetails = createAction(actionTypes.SET_ADD_KW_NOTIFICATION_DETAILS);
export const resetIsKeywordsOptimized = createAction(actionTypes.RESET_IS_KEYWORDS_OPTIMIZED_STATUS);
export const resetIsKeywordsCategoryOptimized = createAction(actionTypes.RESET_IS_KEYWORDS_CATEGORY_MAPPED_STATUS);
export const resetKwdCategoryAndKwdOptimizationStatus = createAction(actionTypes.RESET_KWD_CATEGORY_AND_KWD_OPTIMIZATION_STATUS);
export const callToGetSuggestedCompetitorDomains = createAction(actionTypes.CALL_TO_GET_SUGGESTED_COMPETITOR_DOMAINS);
export const saveSuggestedCompetitorDomains = createAction(actionTypes.SAVE_SUGGESTED_COMPETITOR_DOMAINS);
export const setSuggestedCompetitorDomainAPIStatus = createAction(actionTypes.SET_SUGGESTED_COMPETITOR_DOMAIN_API_STATUS);
export const setCurrentPaginationForKeywordDetails = createAction(actionTypes.SET_CURRENT_PAGINATION_FOR_KEYWORD_DETAILS);
export const callToSaveUploadProjectKeyword = createAction(actionTypes.CALL_TO_SAVE_UPLOAD_PROJECT_KEYWORD);
export const setKeywordUploadFile = createAction(actionTypes.SET_KEYWORD_UPLOAD_FILE);
export const setIsUploadFileKeywordsSuccess = createAction(actionTypes.SET_IS_UPLOAD_FILE_KEYWORD_SUCCESS);
export const resetDomainDetailsStateStatus = createAction(actionTypes.RESET_DOMAIN_DETAILS_STATE_STATUS);
export const setIsResetAddKeywordStateStatus = createAction(actionTypes.RESET_ADD_KEYWORD_STATE_STATUS);
export const setProjectDevicetype = createAction(actionTypes.SET_PROJECT_DEVICE_TYPE);
export const setErrorFileStatus = createAction(actionTypes.SET_ERROR_FILE_STATUS);
export const setErrorFileAPIResponse = createAction(actionTypes.SET_ERROR_FILE_API_RESPONSE);
export const setIsResetFileUploadStatus = createAction(actionTypes.RESET_FILE_UPLOAD_STATUS);
export const setProjectCrawlFreq = createAction(actionTypes.SET_PROJECT_CRAWLING_FREQUENCY);
export const setProjectInitialCrawlFreq = createAction(actionTypes.SET_PROJECT_INITIAL_CRAWLING_FREQUENCY);
