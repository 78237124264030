import { isUndefined, omit } from "lodash";
import {
    PerformanceMetricsPayloadInterface,
    ModelScorePayloadInterface,
    ModelScoreDetailsPayloadInterface,
    SimulateModelScoreDetailsPayloadInterface,
    CompetitorsScorePayloadInterface,
    LiveNonLivePerformanceMetricsPayloadInterface,
    InitialParametersforFileuploadInterface,
    SavedSimulationScoresPayloadInterface,
    CaptureTechAuthInformationPayloadInterface,
    UpdateDescriptionPayloadInterface,
    SidPayloadInterface,
    SimulateKeywordStuffingPayloadInterface,
    SimulationDownloadApiInterface,
    CompetitorsScoreInitiatePayloadInterface,
    SimulationDownloadApiTrackInterface,
    VersionListInputPayloadInterface,
    MetaInformationInterface
} from "./types";
import { FileUploadInterfae, SimulationContentInterface } from "../../kw-url-search/ducks/types";
import { ParserInterface } from "../../../app/duck/types";
import { ApiConfig } from "../../../app/config/apiConfig";
import { getAxiosParam, omitOptionalArgs } from "../../../app/duck/utils";
import { ApiPath, METHOD } from "../../../app/config/apiPath";
import ServiceRequest from "../../../app/services";
import { NotifyErrorInterface } from "../../../beta/page-optimizer/ducks/types";

class SimulatorApis {

    public *fetchPerformanceScore(
        payload: PerformanceMetricsPayloadInterface,
        parser: ParserInterface
    ): // tslint:disable-next-line:no-any
        any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(payload)
            },
            method: METHOD.POST,
            url: ApiPath.AGGREGATED_PERFORMANCE.replace("{{tenantCode}}", parser.tenantCode)
        };
        const scores = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return scores;
    }

    public *fetchModelScore(
        payload: ModelScorePayloadInterface,
        parser: ParserInterface,
        isLive: boolean
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            ...(isLive && {
                data: {
                    ...omitOptionalArgs(payload)
                }
            }),
            method: !isLive ? METHOD.GET : METHOD.POST,
            url: !isLive
                ? (payload.isBeta ? ApiPath.NON_LIVE_URL_AGGREGATED_MODEL_SCORE_BETA : ApiPath.NON_LIVE_URL_AGGREGATED_MODEL_SCORE).replace(
                    "{{tenantCode}}",
                    parser.tenantCode
                )
                : (payload.isBeta ? ApiPath.AGGREGATED_MODEL_SCORE_BETA : ApiPath.AGGREGATED_MODEL_SCORE).replace(
                    "{{tenantCode}}",
                    parser.tenantCode
                )
        };
        const scores = yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(payload, ["isBeta"])));
        return scores;
    }

    // tslint:disable-next-line: typedef
    public *fetchKeywordStuffingDetail(
        payload: SimulateKeywordStuffingPayloadInterface,
        parser: ParserInterface
    ): // tslint:disable-next-line:no-any
        any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(payload)
            },
            method: METHOD.POST,
            url: ApiPath.AGGREGATED_MODEL_KEYWORD_STUFFING.replace("{{tenantCode}}", parser.tenantCode)
        };
        const scores = yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(payload)));
        return scores;
    }

    // tslint:disable-next-line: typedef
    public *fetchModelScoreDetail(
        payload: ModelScoreDetailsPayloadInterface | ModelScoreDetailsPayloadInterface,
        parser: ParserInterface
    ): // tslint:disable-next-line:no-any
        any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(payload)
            },
            method: METHOD.POST,
            url: (payload.isBeta ? ApiPath.AGGREGATED_MODEL_SCORE_DETAILS_BETA : ApiPath.AGGREGATED_MODEL_SCORE_DETAILS).replace(
                "{{tenantCode}}",
                parser.tenantCode
            )
        };
        const scores = yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(payload, ["isBeta"])));
        return scores;
    }

    // tslint:disable-next-line: typedef
    public *fetchOnPageElements(
        payload: PerformanceMetricsPayloadInterface,
        parser: ParserInterface,
        fileUploadRequestId: InitialParametersforFileuploadInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            ...(fileUploadRequestId.request_id && {
                params: {
                    ...fileUploadRequestId
                }
            }),

            data: {
                ...omitOptionalArgs(payload)
            },
            method: fileUploadRequestId.request_id ? METHOD.GET : METHOD.POST,
            url: fileUploadRequestId.request_id
                ? (payload.isBeta ? ApiPath.FILE_UPLOAD_ON_PAGE_ELEMENTS_BETA : ApiPath.FILE_UPLOAD_ON_PAGE_ELEMENTS).replace(
                    "{{tenantCode}}",
                    parser.tenantCode
                )
                : // : ApiPath.ON_PAGE_ELEMENTS.replace("{{tenantCode}}", parser.tenantCode)
                (payload.isBeta ? ApiPath.ON_PAGE_ELEMENTS_BETA : ApiPath.ON_PAGE_ELEMENTS).replace("{{tenantCode}}", parser.tenantCode)
        };
        const elements = yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(payload, ["isBeta"])));
        return elements;
    }

    public *fetchPostSimulationModeleScoreDetail(
        payload: SimulateModelScoreDetailsPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(payload)
            },
            method: METHOD.POST,
            url: (payload.isBeta
                ? ApiPath.SIMULATE_AGGREGATED_MODEL_SCORE_DETAILS_BETA
                : ApiPath.SIMULATE_AGGREGATED_MODEL_SCORE_DETAILS
            ).replace("{{tenantCode}}", parser.tenantCode)
        };
        const scores = yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(payload, ["isBeta"])));
        return scores;
    }

    // tslint:disable-next-line: typedef
    public *saveSimulationContent(
        payload: SimulationContentInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(payload)
            },
            method: METHOD.POST,
            url: (payload.isBeta ? ApiPath.SAVE_SIMULATION_CONTENT_BETA : ApiPath.SAVE_SIMULATION_CONTENT).replace(
                "{{tenantCode}}",
                parser.tenantCode
            )
        };
        const resp = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return resp;
    }

    // tslint:disable-next-line: typedef
    public *saveSnapshotAuthTechInformation(
        // tslint:disable-next-line: no-any
        payload: CaptureTechAuthInformationPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omit(payload, ["isBeta"])
            },
            method: METHOD.POST,
            url: (payload.isBeta
                ? ApiPath.SAVE_SIMULATION_AUTHORITY_TECHNICAL_PARAMETER_BETA
                : ApiPath.SAVE_SIMULATION_AUTHORITY_TECHNICAL_PARAMETER
            ).replace("{{tenantCode}}", parser.tenantCode)
        };
        const authTechInformataion = yield ServiceRequest.invoke(axiosParam);
        return authTechInformataion;
    }

    // tslint:disable-next-line: typedef
    public *fetchCompetitorsScore(
        payload: CompetitorsScorePayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(payload)
            },
            method: METHOD.POST,
            url: payload.isBeta
                ? ApiPath.COMPETITORS_SCORE_BETA.replace("{{tenantCode}}", parser.tenantCode)
                : ApiPath.COMPETITORS_SCORE.replace("{{tenantCode}}", parser.tenantCode)
        };
        const scores = yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(payload, ["isBeta"])));
        return scores;
    }

    // tslint:disable-next-line: typedef
    public *getCompetitorScoreInitiate(
        payload: CompetitorsScoreInitiatePayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.SAVE_SIMULATION_COMPETITORS_SCORE_INITIATE_REQUEST_URL.replace("{{tenantCode}}", parser.tenantCode),
            data: payload
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    // tslint:disable-next-line: typedef
    public *fetchPreSimKeywordDetails(
        payload: LiveNonLivePerformanceMetricsPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(payload)
            },
            method: METHOD.POST,
            url: (payload.isBeta ? ApiPath.PRE_SIM_KEYWORD_LEVEL_DETAIL_BETA : ApiPath.PRE_SIM_KEYWORD_LEVEL_DETAIL).replace(
                "{{tenantCode}}",
                parser.tenantCode
            )
        };
        return yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(payload, ["isBeta"])));
    }

    public *saveCompetitorScores(
        args: SidPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: {
                sid: args.sid
            },
            url: (args.isBeta ? ApiPath.SAVE_SIMULATION_COMPETITORS_SCORE_BETA : ApiPath.SAVE_SIMULATION_COMPETITORS_SCORE).replace(
                "{{tenantCode}}",
                parser.tenantCode
            )
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(args, ["isBeta"])));
        return response;
    }

    // tslint:disable-next-line: typedef
    public *getContentBasedOnVersion(args: SavedSimulationScoresPayloadInterface) {
        const tenantCode = !isUndefined(args.tenantCode) && args.tenantCode;
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                sid: args.sid
            },
            url: (args.isBeta ? ApiPath.SAVE_SIMULATION_CONTENT_BETA : ApiPath.SAVE_SIMULATION_CONTENT).replace(
                "{{tenantCode}}",
                tenantCode as string
            )
        };
        const versionlist = yield ServiceRequest.invoke(axiosParam);
        return versionlist;
    }

    public *updateDescription(
        payload: UpdateDescriptionPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: omit(payload, ["isBeta"]),
            method: METHOD.POST,
            url: (payload.isBeta ? ApiPath.VERSION_LIST_BETA : ApiPath.VERSION_LIST).replace("{{tenantCode}}", parser.tenantCode)
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *getSimMetaInfo(
        args: SidPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                sid: args.sid
            },
            url: (args.isBeta ? ApiPath.SAVE_SIMULATION_META_INFO_BETA : ApiPath.SAVE_SIMULATION_META_INFO).replace(
                "{{tenantCode}}",
                parser.tenantCode
            )
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(args, ["isBeta"])));
        return response;
    }

    public *getVersionCompetitorScores(
        args: SidPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                sid: args.sid
            },
            url: (args.isBeta ? ApiPath.SAVE_SIMULATION_COMPETITORS_SCORE_BETA : ApiPath.SAVE_SIMULATION_COMPETITORS_SCORE).replace(
                "{{tenantCode}}",
                parser.tenantCode
            )
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(args, ["isBeta"])));
        return response;
    }

    public *getSimulationVersionStatus(
        payload: SidPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                sid: payload.sid ? payload.sid : payload
            },
            url: (payload.isBeta ? ApiPath.SAVE_SIMULATION_VERSION_STATUS_BETA : ApiPath.SAVE_SIMULATION_VERSION_STATUS).replace(
                "{{tenantCode}}",
                parser.tenantCode
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *getSimulationDownload(
        payload: SimulationDownloadApiInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.SIMULATION_DOWNLOAD_API.replace("{{tenantCode}}", parser.tenantCode),
            data: payload,
            responseType: "arraybuffer"
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *getSimulationDownloadContentOnly(
        payload: SimulationDownloadApiInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.SIMULATION_DOWNLOAD_CONTENT_ONLY_API.replace("{{tenantCode}}", parser.tenantCode),
            data: payload,
            responseType: "arraybuffer"
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *getSimulationDownloadContentOnlyInitiate(
        payload: SimulationDownloadApiInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.SIMULATION_DOWNLOAD_CONTENT_ONLY_API.replace("{{tenantCode}}", parser.tenantCode),
            data: payload
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *getSimulationDownloadContentOnlyTrack(
        payload: SimulationDownloadApiTrackInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: payload,
            url: ApiPath.SIMULATION_DOWNLOAD_CONTENT_ONLY_TRACK_API.replace("{{tenantCode}}", parser.tenantCode),
            responseType: "arraybuffer"
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *downloadSimulationPageAssessment(
        // tslint:disable-next-line:no-any
        payload: any,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.SIMULATION_DOWNLOAD_PAGE_ASSESSMENT.replace("{{tenantCode}}", parser.tenantCode),
            data: payload,
            responseType: "arraybuffer"
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *postRecomBulbSubHeaderScores(
        payload: SimulationDownloadApiInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.RECOM_BULB_SUBHEADER_SCORES.replace("{{tenantCode}}", parser.tenantCode),
            data: payload
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *getCompetitorScoreNewKeywordInitiate(
        payload: CompetitorsScoreInitiatePayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.COMPETITORS_SCORE_INITIATE_REQUEST_URL.replace("{{tenantCode}}", parser.tenantCode),
            data: payload
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    // tslint:disable-next-line: typedef
    public *getVersionList(args: VersionListInputPayloadInterface) {
        const tenantCode = !isUndefined(args.tenantCode) && args.tenantCode;
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                url: args.url,
                is_url_live: args.is_url_live
            },
            url: (args.isBeta ? ApiPath.VERSION_LIST_BETA : ApiPath.VERSION_LIST).replace("{{tenantCode}}", tenantCode as string)
        };
        const versionlist = yield ServiceRequest.invoke(axiosParam);
        return versionlist;
    }

    // tslint:disable-next-line: typedef
    public *getScoresBasedOnVersion(args: SavedSimulationScoresPayloadInterface) {
        const tenantCode = !isUndefined(args.tenantCode) && args.tenantCode;
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                sid: args.sid
            },
            url: ApiPath.SCORES_UPDATE_BASED_ON_VERSION.replace("{{tenantCode}}", tenantCode as string)
        };
        const versionlist = yield ServiceRequest.invoke(axiosParam);
        return versionlist;
    }

    public *captureSnapshotMetaInformation(
        payload: MetaInformationInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(payload)
            },
            method: METHOD.POST,
            url: (payload.isBeta ? ApiPath.SAVE_SIMULATION_META_INFO_BETA : ApiPath.SAVE_SIMULATION_META_INFO).replace(
                "{{tenantCode}}",
                parser.tenantCode
            )
        };
        const metaInformataion = yield ServiceRequest.invoke(axiosParam);
        return metaInformataion;
    }

    // tslint:disable-next-line: typedef
    public *fileUploadOnPageElements(
        payload: FileUploadInterfae,
        parser: ParserInterface,
        optionalArgs?: NotifyErrorInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: payload.formData,
            method: METHOD.POST,
            url: (payload.isBeta ? ApiPath.FILE_UPLOAD_ON_PAGE_ELEMENTS_BETA : ApiPath.FILE_UPLOAD_ON_PAGE_ELEMENTS).replace(
                "{{tenantCode}}",
                parser.tenantCode
            )
        };
        const elements = yield ServiceRequest.invoke(getAxiosParam(axiosParam, optionalArgs));
        return elements;
    }

}
export default new SimulatorApis();
